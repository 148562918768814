import store from "@/store";
export  default {
    resetFilter(){
        store.state.filter.clientId = sessionStorage.getItem("loggedInClientId");
        store.state.filter.providerId = 1;
        store.state.filter.search = "";
        store.state.filter.sort ="";
        store.state.filter.pageSize= 10;
        store.state.filter.currentPage = 1;
        store.state.filter.totalCount = 0;
        store.state.deleteDialog = false;
        store.state.allDeleteDialog = false;
        store.state.selectedIds =[];
        store.state.selectedIds.length = 0;
    }
}