<template>
  <div class="container-fluid border-bottom mb-2 p-0">
    <div class="row d-flex align-items-center custom-toolbar">
      <div class="col-12 col-md-8">
        <BreadCrumb :view="view" :showBread="showBread"/>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">

        <el-button v-if="showCancel" @click="GoBack(backRoute)" text bg class="btn btn-light">
          <i class="bi bi-chevron-left me-2 text-capitalize"></i>
          {{ cancelBtnText }}
        </el-button>
        <slot name="select" class="w-100"></slot>
        <el-button :disabled="addDisabled" @click="ChangeRoute(routeName)" v-if="ShowAddBtn"
                   class="border-0 mbtn-primary ms-2 text-capitalize">{{
            btnText
          }}
        </el-button>
      </div>
    </div>
  </div>
<!--  <div class="custom-toolbar d-flex align-items-center justify-content-between border-bottom mb-2">
    <div class="col-12 col-md-8">
      <BreadCrumb :showBread="showBread"/>
    </div>
    <div class="col-12 col-md-4 d-flex">

      <el-button v-if="showCancel" @click="GoBack(backRoute)" text bg class="btn btn-light">
        <i class="bi bi-chevron-left me-2"></i>
        {{ cancelBtnText }}
      </el-button>
      &lt;!&ndash;      <el-select v-if="showSelect" v-model="SelectValue" @change="$emit('selection-changed',SelectValue)">&ndash;&gt;
      &lt;!&ndash;        <el-option&ndash;&gt;
      &lt;!&ndash;            v-for="item in options"&ndash;&gt;
      &lt;!&ndash;            :key="item.value"&ndash;&gt;
      &lt;!&ndash;            :label="item.label"&ndash;&gt;
      &lt;!&ndash;            :value="item.value"&ndash;&gt;
      &lt;!&ndash;        />&ndash;&gt;
      &lt;!&ndash;      </el-select>&ndash;&gt;
      <slot name="select" class="w-100"></slot>
      <el-button :disabled="addDisabled" @click="ChangeRoute(routeName)" v-if="ShowAddBtn"
                 class="border-0 mbtn-primary ms-2">{{
          btnText
        }}
      </el-button>
    </div>
  </div>-->
</template>

<script>

import BreadCrumb from "@/components/Layout/BreedCrumb";
import store from "@/store";

export default {
  name: "ToolBar",
  components: {BreadCrumb},
  props: {
    ShowAddBtn: {
      type: Boolean,
      required: true,
    }, showBread: {
      type: Boolean,
      required: false,
      default: true,
    },
    view:{
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      required: true,
    }, addDisabled: {
      type: Boolean,
      required: false,
    },
    btnText: {
      type: String,
      required: false,
    },
    cancelBtnText: {
      type: String,
      required: false,
      default: "Cancel"
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    routeName: {
      type: String,
      required: false,
    },
    backRoute: {
      type: String,
      required: false,
    }, clearId: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      SelectValue: 1,
    }
  },
  methods: {
    ChangeRoute(name) {
      if (name !== undefined) {
        store.state.pageId = 0;
        this.$router.push("/" + name);
      } else {
        this.$emit('addbtn-clicked');
      }
    },
    GoBack(name) {
      if (name !== undefined) {
        store.state.pageId = 0;
        this.$router.push("/" + name);
      } else {
        this.$emit('cnclbtn-clicked');
      }
    },
    selectEvent() {
      this.$emit('selection-changed', this.SelectValue)
    }
  },
  created() {
    this.selectEvent()
  }

}
</script>

<style scoped>

</style>