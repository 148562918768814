
import axios from "axios"
import Config from "@/Configuration/Config";
export default {
    allUrl: Config.apiBaseUrl + "market/getmarkets?search=",
    getAllUrl: Config.apiBaseUrl + "market/getmarkets?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "market/getmarketbyid?marketId=",
    addUrl: Config.apiBaseUrl + "market/add",
    updateUrl: Config.apiBaseUrl+"market/update",
    deleteUrl: Config.apiBaseUrl+"market/delete?marketId=",



    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(market) {
        return axios.post(this.addUrl, market);
    },
    update(market) {
        return axios.put(this.updateUrl, market);
    },
    delete(marketId) {
        return axios.delete(this.deleteUrl+marketId);
    },
}