import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allurl: Config.apiBaseUrl + "client/getclients?status=",
    getallurl: Config.apiBaseUrl + "client/getclients?status=1&limit=1500",
    geturl: Config.apiBaseUrl + "client/getclientbyid?clientId=",
    addurl: Config.apiBaseUrl + "client/add",
    updateurl: Config.apiBaseUrl + "client/update",
    deleteurl: Config.apiBaseUrl + "client/delete?clientId=",
    activateurl: Config.apiBaseUrl + "client/activate?clientId=",
    deactivateurl: Config.apiBaseUrl + "client/deactivate?clientId=",
    registerUrl: Config.apiBaseUrl+"client/register",
    getLanguageUrl: Config.apiBaseUrl+"user/getclientlanguage?iso=",
    all(filter) {
        return axios.get(this.allurl + filter.status + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getallurl);
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    addClient(client) {
        return axios.post(this.addurl, client);
    },
    updateClient(client) {
        return axios.put(this.updateurl, client);
    },
    deleteClient(id) {
        return axios.delete(this.deleteurl + id);
    },
    activate(id) {
        return axios.get(this.activateurl + id);
    },
    deactivate(id) {
        return axios.get(this.deactivateurl + id);
    },
    register(client){
      return axios.post(this.registerUrl, client)
    },
    getClientLanguage(iso) {
        return axios.get(this.getLanguageUrl+iso);
    }
}