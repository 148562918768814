import Config from "@/Configuration/Config";
import axios from "axios"


export default {
    allurl: Config.apiBaseUrl + "provider/getproviders?clientId=",
    getallurl: Config.apiBaseUrl + "provider/getproviders?clientId=",
    geturl: Config.apiBaseUrl + "provider/getproviderbyid?providerId=",
    addurl: Config.apiBaseUrl + "provider/add",
    updateurl: Config.apiBaseUrl + "provider/update",
    deleteurl: Config.apiBaseUrl + "provider/delete?providerId=",


    All(filter) {
        return axios.get(this.allurl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(clientId) {
        return axios.get(this.getallurl + clientId + "&limit=1500");
    },
    get(providerId) {
        return axios.get(this.geturl + providerId);
    },
    add(provider) {
        return axios.post(this.addurl, provider)
    },
    update(provider) {
        return axios.put(this.updateurl, provider)
    },
    deleteProvider(id) {
        return axios.delete(this.deleteurl + id)
    }


}