<template>
  <el-dialog
      v-model="$store.state.deleteDialog"
      title="Delete Provider"
      width="30%"
  >
    <span v-if="selectedIds.length == 1">Do you want to delete {{ title }}</span>
    <span v-else>Do you want to delete selected providers.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$store.state.deleteDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white border-0" @click="$store.state.deleteDialog = false, deleteClient()">
          Delete
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import providerService from "@/Services/ComponentsServices/ProviderService";

export default {
  name: "DeleteProvider",
  emits: ['deleteSuccess', 'refresh',],
  props: {
    title: {
      type: String,
      required: false,
    },
    selectedIds: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      ShowDialog: true,
    };
  },
  methods: {
    async deleteClient() {
      try {
        let response = new Object();
        for (const e of this.selectedIds) {
          // eslint-disable-next-line no-unused-vars
          response = await providerService.deleteProvider(e);
        }
        if (this.selectedIds.length > 1) {
          ShowMessage("success", "Providers Deleted Successfully");
        } else {
          ShowMessage("success", this.title + " Deleted Successfully");
        }
        this.$emit("deleteSuccess");
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
  },
};
</script>

<style scoped></style>
