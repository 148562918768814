<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="false" :showSelect="true" @selection-changed="getRooms">
      <template v-slot:select>
        <el-select v-if="clientId < 1" class=" w-100" filterable @change="ChangeClient" v-model="filter.clientId">
          <el-option
              v-for="item in Clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button class="bg-danger text-white fw-normal me-2 border-0"
                       @click="$store.state.deleteDialog = !$store.state.deleteDialog"
                       v-if="selectedIds.length > 1">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} selected
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search') ??'Search here..'"
                    @clear="getRooms" v-model="filter.search"
                    @keyup="getRooms"
                    clearable>
            <template #append>
              <el-button @click="getRooms">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="Rooms" :default-sort="{ prop: 'property', order: 'ascending' }"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <el-table-column align="center" type="selection" width="55"/>
            <el-table-column :label="getMessageByCode('property') ??'Property'" prop="property" sortable>
              <template #default="scope">
            <span class="router-text" @click="editRoom(scope.row)">{{
                scope.row.property
              }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('provider') ??'Provider'" width="auto" prop="provider"/>
            <el-table-column  :label="getMessageByCode('name') ??'Name'" width="auto" prop="provider"/>
            <el-table-column  :label="getMessageByCode('room') ??'Room'" width="auto" prop="roomName"/>
          </el-table>
        </div>
      </div>


    </div>

    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import roomService from "@/Services/ComponentsServices/roomService";
import ClientService from "@/Services/ComponentsServices/clientService";
import store from "@/store";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "EmptyRooms",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      Rooms: [],
      clientId: sessionStorage.getItem("clientId"),
      Clients: [],
      selectedIds: [],
      translations: [],
      headers: [
        {label: "Property", prop: "property"},
        {label: "Provider", prop: "provider"},
        {label: "Name", prop: "roomName"},
        {label: "Room", prop: "roomNo"},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    editRoom(room) {
      store.state.pageId = room.propertyId;
      this.$router.push('/property');
    },
    deleteTenantId(tenant) {
      this.tenantName = tenant.firstName;
      this.selectedIds.push(tenant.id)
      store.state.deleteDialog = true;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getRooms();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getRooms();
    },
    ChangeClient() {
      this.getRooms();
    },
    async getRooms() {
      try {
        this.loading = true;
        if (this.clientId === null || this.clientId === undefined) {
          this.clientId = 0;
        }
       let response = await roomService.getemptyrooms(this.filter)
        this.Rooms = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          let response = await ClientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        this.getRooms();
      } catch (e) {
        console.log(e);
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getClients();
  }
}
</script>

<style scoped>

</style>