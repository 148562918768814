<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">

          <router-link to="clients">
            <el-button  text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              Cancel
            </el-button>
          </router-link>
          <el-button :disabled="addDisabled" @click="UpdateClient" v-if="clientEdit"
                     class="border-0 mbtn-primary ms-2">Update Client
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-tabs type="border-card" class="demo-tabs">
        <el-tab-pane>
          <template #label>
        <span class="custom-tabs-label">
          <i class="bi bi-card-checklist me-1"></i>
          <span>Details</span>
        </span>
          </template>
          <el-form ref="clientForm" :model="Client">
            <div class="row">
              <div class="col-12 col-md-4">
                <label class="lblText required">Name</label>
                <el-form-item prop="name" :rules="formRules.nameRule">
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.name"
                      type="text"
                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText required">Email</label>
                <el-form-item prop="email" :rules="formRules.emailRule">
                  <el-input
                      :disabled="!clientEdit"
                      @keydown.space.prevent
                      v-model="Client.email"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Mobile</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.mobile"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Display Name</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.displayName"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText required">User Email</label>
                <el-form-item prop="userEmail" :rules="formRules.userEmail">
                  <el-input
                      :disabled="!clientEdit"
                      @keydown.space.prevent
                      v-model="Client.userEmail"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Website</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.website"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Address 1</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.address1"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Address 2</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.address2"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Address 3</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.address3"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Post Code</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.postCode"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Town/City</label>
                <el-form-item>
                  <el-input
                      :disabled="!clientEdit"
                      v-model="Client.city"
                      type="text"

                  />
                </el-form-item>
              </div>
              <div class="col-12 col-md-4">
                <label class="lblText ">Country</label>
                <el-form-item>
                  <el-select v-model="Client.county"   :disabled="!clientEdit" filterable placeholder="Select" class="w-100">
                    <el-option
                        v-for="item in Countries"
                        :key="item.optId"
                        :label="item.optTitle"
                        :value="item.optTitle"
                        style="font-weight: normal; color: #0a0a0a"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form>

        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";
import ClientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import {checkPermission} from "@/Utility/CheckPermission";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";

export default {
  name: "EditClient",
  components: {BreadCrumb},
  data() {
    return {
      mainBtnText: "Add Client",
      addDisabled: false,
      clientEdit: false,
      clientCreate: false,
      loader: false,
      Countries: [],
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ], userEmail: [
          {required: true, message: 'Please enter the user email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid user email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],
      },
      Client: {
        id: store.state.pageId,
        name: "",
        email: "",
        mobile: "",
        website: "",
        address1: "",
        address2: "",
        address3: "",
        postCode: "",
        city: "",
        county: "",
        displayName: "",
        userEmail: "",
        password: "",
        isActive: true
      },
    }
  },
  methods: {
    validateEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    AddOrUpdateClient() {
      switch (this.Client.id) {
        case 0:
          this.AddClient();
          break;
        default:
          this.UpdateClient();
          break;
      }
    },
    async AddClient() {
      this.loader = true;
      this.$refs.clientForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            let response = await ClientService.addClient(this.Client);

            ShowMessage("success", "Client add successful");
            this.loader = false;
          } catch (e) {
            ShowMessage("error", e.response.data.message);
            this.loader = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loader = false;
        }
      });
      this.loader = false;
    },
    async UpdateClient() {
      this.loader = true;
      this.$refs.clientForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            let response = await ClientService.updateClient(this.Client);

            ShowMessage("success", "Client updated successfully");
            this.loader = false;
          } catch (e) {
            console.log(e)
            ShowMessage("error", "Client adding failed");
            this.loader = false;
          }
        } else {
          ShowMessage("error", "Please enter the required fields!")
          return;
        }
      });
    },
    async getClient() {
      try {
        this.loader = true;
        let response = await ClientService.getbyId(this.Client.id);
        this.Client = response.data ?? {};
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    }
    ,
    async getCountries() {
      try {
        this.loader = true;
        let response = await optionService.getOptionsByHeaderId(7)
        this.Countries = response.data ?? {};
        if (this.Countries.length > 0) {
          if (this.Client.county == "") {
            this.Client.county = this.Countries[0].optTitle;
          }
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
        this.loader = false;
      }
    }
  },
  created() {
    this.clientEdit = checkPermission("MD_CLIENT_EDIT");
    this.getCountries();
    if (store.state.pageId > 0) {
      this.mainBtnText = "Update Client";
      this.getClient();
    } else {
      this.mainBtnText = "Add Client";
    }
  }
}
</script>

<style scoped>

</style>