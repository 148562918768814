<template>
  <el-dialog
      v-model="$store.state.allDeleteDialog"
      :title="'Delete ' + entityName"
      width="30%"
  >
    <span>Do you want to delete {{ this.$store.state.selectedIds.length }} <span class="text-lowercase"> {{
        entityName
      }}</span></span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$store.state.allDeleteDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white" @click="$store.state.allDeleteDialog = false, deleteEntity()">
          Delete
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import ApiService from "@/Services/ApiService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "DeleteMultiple",
  emits: ['deleteSuccess'],
  props: {
    entityName: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  methods: {
    deleteEntity: async function () {
      try {
        for (let i = 0; i < this.$store.state.selectedIds.length; i++) {
          let response = await ApiService.delete(
              this.slug, this.resource, this.$store.state.selectedIds[i]
          );
          if (response.status === 200) {
            ShowMessage("success", this.slug + " Deleted Successfully");
            this.$emit("deleteSuccess");
          }
        }

      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
  }
}
</script>

<style scoped>

</style>