import {createRouter, createWebHistory} from "vue-router";
import Login from "@/components/Authentication/Login.vue";
import SADashboard from "@/components/Dashboard/SADashboard";
import CLDashboard from "@/components/Dashboard/CLDashboard";
import Clients from "@/components/Clients/Clients";
import AddClient from "@/components/Clients/AddClient";
import EditClient from "@/components/Clients/EditClient";
import Providers from "@/components/Providers/ProvidersList.vue";
import AddProvider from "@/components/Providers/AddProvider.vue";
import Compliance from "@/components/Compliance/ComplianceList.vue";
import Properties from "@/components/Properties/PropertiesList.vue";
import AddProperty from "@/components/Properties/AddProperty.vue";
import Users from "@/components/Users/UsersList.vue";
import Tenant from "@/components/Tenant/TenantList.vue"
import AddTenant from "@/components/Tenant/AddTenant.vue"
import Settings from "@/components/Settings/Settings";
import Documents from "@/components/Documents/Documents.vue"
import UserProfile from "@/components/User/UserProfile"
import EmptyRooms from "@/components/Properties/Rooms/EmptyRooms"
import {isAuthenticated, isAdmin} from "@/components/Authentication/Authenticate";
import ForBidden from "@/components/404/Forbidden.vue";
import PageNotFound from "@/components/404/PageNotFound";
import {ShowMessage} from "@/Utility/Utility";
import OptionsList from "@/components/Options/OptionsList.vue";
import RolesList from "@/components/Roles/RolesList.vue"
import RolesPermissions from "@/components/Roles/RolesPermissions.vue";
import ForgotPassword from '@/components/Authentication/ForgotPassword.vue'
import ResetPassword from "@/components/Authentication/ResetPassword.vue"
import TemplatesList from "@/components/Templates/TemplatesList.vue"
import DocumentsExpiry from "@/components/Reports/DocumentsExpiry.vue";
import MissingDocuments from "@/components/Reports/MissingDocuments.vue";
import TenantsReport from "@/components/Reports/TenantsReport.vue";
import RoomReport from "@/components/Reports/EmptyRooms.vue";
import MarketList from "@/components/Market/MarketList.vue";
import PackagesList from "@/components/Market/PackagesList.vue"
import Signup from "@/components/Authentication/Signup.vue";
import Register from "@/components/Authentication/Register.vue";
import AddUser from "@/components/Users/AddUser.vue"
import {checkPermission} from "@/Utility/CheckPermission";
import {getMessageByCode} from "@/Utility/getMessagebyCode";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },  {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    }, {
        path: "/password-reset",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/403",
        name: "ForBidden",
        component: ForBidden,
    },

    {
        path: "/dashboard",
        name: "SADashboard",
        component: SADashboard,
        meta: {
            pageTitle:   'Dashboard',
            breadcrumbs: ['Dashboard'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs =  getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/home",
        name: "CLDashboard",
        component: CLDashboard,
        meta: {
            pageTitle: "Dashboard",
            breadcrumbs: ["Dashboard"],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs =  getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated() && !isAdmin()) {
                next();
            } else if (isAuthenticated() && isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/clients",
        name: "clients",
        component: Clients,
        meta: {
            pageTitle: 'Client',
            breadcrumbs: ['Client'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_CLIENT_VIEW")) {
                if (isAuthenticated() && isAdmin()) {
                    next();
                } else if (isAuthenticated() && !isAdmin()) {
                    ShowMessage("error", "Unauthorized");
                    router.push("/403")
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        }
    },
    {
        path: "/client",
        name: "client",
        component: AddClient,
        props: true,
        meta: {
            pageTitle:  'Client',
            breadcrumbs: ['Add'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/edit-client",
        name: "editClient",
        component: EditClient,
        props: true,
        meta: {
            pageTitle: "Edit Client",
            breadcrumbs: ["Client", "Edit"],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/providers",
        name: "providers",
        component: Providers,
        meta: {
            pageTitle:'Providers',
            breadcrumbs: ['Providers'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

           if(checkPermission("MD_PRO_VIEW")){
               if (isAuthenticated) {
                   // User is authenticated, allow access to the route
                   next();
               } else {
                   // User is not authenticated, redirect to the login page
                   next('/');
               }
           }
           else {
               ShowMessage("error", "Unauthorized");
               router.push("/403")
           }
        },
    },
    {
        path: "/provider",
        name: "add provider",
        component: AddProvider,
        meta: {
            pageTitle: 'Providers',
            breadcrumbs: ['Provider', 'Add'],
        },

        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/compliances",
        name: "Compliances",
        component: Compliance,
        meta: {
            pageTitle: "Compliances",
            breadcrumbs: ["Compliances"],
        },
        beforeEnter: (to, from, next) => {
            if(checkPermission("MD_COMP_VIEW")){
                if (isAuthenticated() && isAdmin()) {
                    next();
                } else if (isAuthenticated() && !isAdmin()) {
                    ShowMessage("error", "Unauthorized");
                    router.push("/403")
                } else {
                    next('/');
                }
            }
            else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }

        },
    },
    //   {
    //     path: "/client_details",
    //     name: "add clients",
    //     component: AddClient,
    //     meta: {
    //       pageTitle: "Client",
    //       breadcrumbs: ["Client", "Add"],
    //     },
    //   },
    {
        path: "/properties",
        name: "Properties",
        component: Properties,
        meta: {
            pageTitle: 'Properties',
            breadcrumbs: ['Properties'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if(checkPermission("MD_PROP_VIEW")){
                if (isAuthenticated) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            }
            else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/property",
        name: "add property",
        component: AddProperty,
        meta: {
            pageTitle:  'Property',
            breadcrumbs: ['Property', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            pageTitle:  'Users',
            breadcrumbs: [ 'Users'],
        },
        beforeEnter: (to, from, next) => {
          if(checkPermission("MD_USER_VIEW")){
              if (isAuthenticated()) {
                  next();
              } else {
                  next('/');
              }
          }
          else {
              ShowMessage("error", "Unauthorized");
              router.push("/403")
          }
        },
    },{
        path: "/add-user",
        name: "Add User",
        component: AddUser,
        meta: {
            pageTitle: getMessageByCode('add_user') ?? 'Add User',
            breadcrumbs: [getMessageByCode('add_user') ?? 'Add User'],
        },
        beforeEnter: (to, from, next) => {
          if(checkPermission("MD_USER_CREATE")){
              if (isAuthenticated()) {
                  next();
              } else {
                  next('/');
              }
          }
          else {
              ShowMessage("error", "Unauthorized");
              router.push("/403")
          }
        },
    },
    //   {
    //     path: "/client_details",
    //     name: "add clients",
    //     component: AddClient,
    //     meta: {
    //       pageTitle: "Client",
    //       breadcrumbs: ["Client", "Add"],
    //     },
    //   },
    {
        path: "/tenants",
        name: "Tenants",
        component: Tenant,
        meta: {
            pageTitle: 'Tenants',
            breadcrumbs: ['Tenants'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if(checkPermission("MD_TEN_VIEW")){
                if (isAuthenticated) {
                    // User is authenticated, allow access to the route
                    next();
                } else {
                    // User is not authenticated, redirect to the login page
                    next('/');
                }
            }
            else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/tenant",
        name: "add tenant",
        component: AddTenant,
        meta: {
            pageTitle: 'Tenant',
            breadcrumbs: ['Tenant', 'Add'],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    /*{
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            pageTitle: "Settings",
            breadcrumbs: ["Settings",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },*/
    {
        path: "/markets",
        name: "Markets",
        component: MarketList,
        meta: {
            pageTitle: "Markets",
            breadcrumbs: ["Markets",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
     {
        path: "/packages",
        name: "PackagesList",
        component: PackagesList,
        meta: {
            pageTitle: "Packages",
            breadcrumbs: ["Packages",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },

    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            pageTitle: "Settings",
            breadcrumbs: ["Settings",],
        },
        beforeEnter: (to, from, next) => {
            if(checkPermission("MD_CONFIG_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            }
            else {
                ShowMessage("error","Unauthorized");
                router.push("/403")
            }
        },
    }, {
        path: "/templates",
        name: "templates",
        component: TemplatesList,
        meta: {
            pageTitle: "Templates",
            breadcrumbs: ["Templates",],
        },
        beforeEnter: (to, from, next) => {
            if(checkPermission("MD_TEMPLATE_VIEW")) {
                if (isAuthenticated() ) {
                    next();

                } else {
                    next('/');
                }
            }
            else {
                ShowMessage("error","Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/roles",
        name: "roles",
        component: RolesList,
        meta: {
            pageTitle: "Roles",
            breadcrumbs: ["Roles",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && checkPermission("MD_ROLE_VIEW")) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/roles-permissions",
        name: "roles-permissions",
        component: RolesPermissions,
        meta: {
            pageTitle: "Role Permissions",
            breadcrumbs: ["Role Permissions",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/options",
        name: "options",
        component: OptionsList,
        meta: {
            pageTitle: "Options",
            breadcrumbs: ["Options",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/documents", name: "documents", component: Documents, meta: {
            pageTitle: "Documents", breadcrumbs: ["Documents",],
        }, beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOCUMENT_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/profile", name: "profile", component: UserProfile, meta: {
            pageTitle: "Profile", breadcrumbs: ["Profile",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_USER_PROFILE_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/empty_rooms", name: "emptyrooms", component: EmptyRooms, meta: {
            pageTitle:  'Empty Rooms', breadcrumbs: ['Empty Rooms',],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check
            if (isAuthenticated && checkPermission("MD_ROOM_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/document_expiry", name: "documentexpiry", component: DocumentsExpiry, meta: {
            pageTitle:"Documents Expiry", breadcrumbs: [ "Documents Expiry",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOC_EXP_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/missing_documents", name: "missingdocuments", component: MissingDocuments, meta: {
            pageTitle:"Missing Documents", breadcrumbs: [ "Missing Documents"],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_DOC_MISS_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/tenants_report", name: "tenants_report", component: TenantsReport, meta: {
         pageTitle: "Tenants Report", breadcrumbs: ["Tenants Report",],
        },
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_TEN_REPORT_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },
    {
        path: "/empty-rooms", name: "empty-rooms", component: RoomReport, meta: {
            pageTitle: "Empty Rooms", breadcrumbs: ["Empty Rooms",],
        },
        beforeEnter: (to, from, next ) => {
            // Check if the user is authenticated
            // Implement your own authentication check

            if (isAuthenticated && checkPermission("MD_ROOM_REP_VIEW")) {
                // User is authenticated, allow access to the route
                next();
            } else {
                // User is not authenticated, redirect to the login page
                next('/');
            }
        },
    },

    {
        path: '/404',
        name: 'NotFound',
        component: PageNotFound
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // Check if the requested route exists
    if (router.hasRoute(to.name)) {
        next(); // Route exists, proceed with the navigation
    } else {
        next({name: 'NotFound'}); // Route not found, redirect to the not-found route
    }
});

//let propertyView = checkPermission("MD_PROP_VIEW");
//let clientView = checkPermission("MD_CLIENT_VIEW");
//let providerView = checkPermission("MD_PRO_VIEW");
//let tenantView = checkPermission("MD_TEN_VIEW");
//let complianceView = checkPermission("MD_COMP_VIEW");
//let userView = checkPermission("MD_USER_VIEW");
//let roleView = checkPermission("MD_ROLE_VIEW");
//let missDocView = checkPermission("MD_DOC_MISS_VIEW");
//let docExpView = checkPermission("MD_DOC_EXP_VIEW");
//let tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
//let roomReportView = checkPermission("MD_ROOM_REP_VIEW");
//let templateView = checkPermission("MD_TEMPLATE_VIEW");






export default router;
