<template>
  <el-dialog
      v-model="$store.state.deleteDialog"
      :title="'Delete ' + title"
      width="30%"
  >
    <span>Do you want to delete {{ $store.state.entityName  }}</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button  @click="$store.state.deleteDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white border-0" @click="$store.state.deleteDialog = false, deleteEntity()">
          Delete
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import ApiService from "@/Services/ApiService";
import {ShowMessage} from "@/Utility/Utility";
export default {
  name: "DeleteEntity",
  emits:['deleteSuccess','refresh',],
  props: {
    title: {
      type: String,
      required: true,
    },
    selectedRows: {
      type: Array,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ShowDialog: true,
    };
  },
  methods: {
    deleteEntity: async function () {
      try {
        let response = await ApiService.delete(
            this.slug , this.resource, this.$store.state.entityId
        );
        if (response.status === 200) {
          ShowMessage("success", this.title+" Deleted Successfully");
          this.$emit("deleteSuccess");
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
  },
};
</script>

<style scoped></style>
