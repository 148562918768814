<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <el-button @click="showAddDialog"
                      class="border-0 mbtn-primary ms-2 text-capitalize">{{ getMessageByCode('add_mar')??'Add Market' }}
          </el-button>
        </div>
      </div>
    </div>
    <el-table  :data="Markets"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe >
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column :label="getMessageByCode('title')??'Title'" prop="title" sortable></el-table-column>
      <el-table-column :label="getMessageByCode('active')??'Active'" prop="isActive">
        <template #default="scope">
          <el-tag v-if="scope.row.isActive" type="success" size="small">Yes</el-tag>
          <el-tag v-else type="danger" size="small">Yes</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="130">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
                class="mgc_edit_line text-primary action-btn"
                @click="editMarket(scope.row,'edit')"
            ></i>
            <i  @click="editMarket(scope.row,'del')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


<!--    Dialogs-->
<!--    Edit Dialog-->
    <el-dialog
        v-model="addMarketDialog"
        :title="market.id > 0 ? 'Update Market' : 'Add Market'"
        width="30%"
    >
     <el-form label-position="top">
       <el-form-item>
         <template #label>
           <span class="required">Title</span>
         </template>
         <el-input v-model="market.title"></el-input>
       </el-form-item>
     </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="addMarketDialog = false">Cancel</el-button>
         <el-button v-if="market.id === 0" :disabled="!market.title" type="primary" @click="addMarketDialog = false, addMarket()">
         Add
        </el-button>
        <el-button v-if="market.id > 0" :disabled="!market.title" type="primary" @click="addMarketDialog = false, updateMarket()">
         Update
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="deleteDialog"
        title="Delete"
        width="30%"
    >
     <span>Are you sure? You want to delete <span class="mfw-bold text-danger">{{market.title}}</span> market? </span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deleteDialog = false">Cancel</el-button>
        <el-button :disabled="!market.title" class="bg-danger text-white border-0" @click="deleteDialog = false, deleteMarket()">
        Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import marketService from "@/Services/ComponentsServices/marketService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name:"MarketList",
  components: {BreadCrumb},
  data(){
    return{
      loading: false,
      Markets: [],
      translations: [],
      addMarketDialog: false,
      deleteDialog: false,
      market:{
        "id": 0,
        "title": "",
        "isActive": true,
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods:{
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getMarkets();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getMarkets();
    },
    editMarket(market, type){
      this.market = { ...market };
      if(type === "edit")
      {
        this.addMarketDialog = true;
      }
      else {
        this.deleteDialog = true;
      }
    },
    async addMarket(){
      try {
        this.loading = true;
        let response = await marketService.add(this.market);
        if(response.status === 200){
          this.getMarkets();
          ShowMessage("success", "Market added successfully");
        }
      }
      catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async updateMarket(){
      try {
        this.loading = true;
        let response = await marketService.update(this.market);
        if(response.status === 200){
          this.getMarkets();
          ShowMessage("success", "Market update successfully");
        }
        this.loading = false;
      }
      catch (e) {
        this.loading = false;
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    async deleteMarket(){
      try {
        this.loading = true;
        let response = await marketService.delete(this.market.id);
        if(response.status === 200){
          this.getMarkets();
          ShowMessage("success", "Market deleted");
        }
        this.loading = false;
      }
      catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    async getMarkets(){
     try {
       this.loading = true;
       let response = await marketService.all(this.filter);
       this.Markets = response.data.items ?? [];
       this.filter.totalCount = response.data.totalCount ?? 0;
       this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
       this.filter.pageSize = response.data.pageSize;
       this.loading = false;
     }
     catch (e) {
       console.log(e);
       this.loading = false;
     }
    },
    showAddDialog(){
      this.market = {
        "id": 0,
        "title": "",
        "isActive": true,
      },
      this.addMarketDialog = true;
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getMarkets();
  }
}
</script>

<style scoped>

</style>