<template>
  <div>
    <el-dialog
        v-model="$store.state.addNoteDialog"
        align-center
        draggable
        width="30%"
    >
      <template #header>
        <span>{{cardTitle}} {{getMessageByCode('note')??'Note'}}</span>
      </template>
      <el-form :model="Note" label-position="top">
        <el-form-item prop="noteCategoryId" label="Category">
          <template #label>
            <span class="required">{{getMessageByCode('category')??'Category'}}</span>
          </template>
          <el-select v-model="Note.noteCategoryId" class="w-100">
            <el-option v-for="opt in Categories" :key="opt.optId" :label="opt.optTitle" :value="opt.optId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('note')??'Note'">
          <el-input :rows="3"
                    type="textarea" v-model="Note.notes"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <el-button text bg @click="$store.state.addNoteDialog = false">{{getMessageByCode('cancel')??'Cancel'}}</el-button>
            <el-button type="primary" class="ms-2" @click="AddOrUpdate(); $store.state.addNoteDialog = false">
              {{ cardTitle }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import store from "@/store";
import optionService from "@/Services/ComponentsServices/optionService";
import noteService from "@/Services/ComponentsServices/noteService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  emits: ['noteadded', 'update:loading'],
  props: {
    noteId: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      cardTitle: "Add",
      Categories: [],
      translations:[],
      Note: {
        "id": 0,
        "noteCategoryId": 0,
        "parentId": 0,
        "typeId": 0,
        "notes": "",
        "clientId": sessionStorage.getItem("clientId"),
        "propertyId": store.state.pageId
      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  watch: {
    noteId: {
      handler(newVal) {
        if (newVal > 0) {
          this.cardTitle = "Update"
          this.Note.id = newVal;
          this.getNote(this.Note.id);
        } else {
          this.resetNote();
        }
      }
    },
  },
  methods: {
    AddOrUpdate() {
      let id = this.Note.id;
      if (id > 0) {
        this.UpdateNote();
      } else if (id === 0) {
        this.AddNote();
      } else {
        this.AddNote();
      }
    },
    async getNote(id) {
      try {
        let response = await noteService.getbyId(id);
        this.Note = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async getNotesCategory() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.filter.clientId,10);
        this.Categories = response.data ?? [];
        if (this.Categories.length > 0) {
          this.Note.noteCategoryId = this.Categories[0].optId;
        }
      } catch (e) {
        console.log()
      }
    },
    async AddNote() {
      try {
        this.$emit("update:loading", true)
        let response = await noteService.add(this.Note);
        if (response.status === 200) {
          ShowMessage("success", "Note added successfully");
          this.$emit("noteadded")
        }
      } catch (e) {
        this.$emit("update:loading", false)
        console.log(e)
      }
    },
    async UpdateNote() {
      try {
        this.$emit("update:loading", true)
        let response = await noteService.update(this.Note);
        if (response.status === 200) {
          ShowMessage("success", "Note updated successfully");
          this.$emit("noteadded")
        }
      } catch (e) {
        this.$emit("update:loading", false)
        console.log(e)
      }
    },
    resetNote() {
      this.cardTitle = "Add";
      this.Note = {
        "id": 0,
        "noteCategoryId": 0,
        "parentId": 0,
        "typeId": 0,
        "notes": "",
        "clientId": sessionStorage.getItem("clientId"),
        "propertyId": store.state.pageId
      };
      this.getNotesCategory();
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getNotesCategory();
    this.cardTitle = this.getMessageByCode('add') ??'Add';
  }
}
</script>

<style scoped>

</style>