<template>
  <div class="container-fluid">
    <ToolBar :show-select="false" :show-add-btn="false"></ToolBar>
    <div class="container-fluid p-0">
      <div class="row p-0">
        <el-form class="">
          <div class="col-12 d-flex justify-content-end">
            <!--            <el-form-item label="Expiring Within">
                          <el-select v-model="filter.days" filterable @change="getDocuments()">
                            <el-option v-for="opt in ExpiryOptions" :key="opt.optId" :label="opt.optTitle"
                                       :value="opt.optId"></el-option>
                          </el-select>
                        </el-form-item>-->
            <el-form-item v-if="clientId == null || clientId == 0" :label="getMessageByCode('scope') ??'Scope'" class="ms-2">
              <el-select  v-model="filter.clientId" filterable @change="getRooms()">
                <el-option v-for="opt in Clients" :key="opt.id" :label="opt.name"
                           :value="opt.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="container-fluid px-0 py-2">
        <div class="d-flex justify-content-end">
          <el-dropdown>
            <el-button type="primary">
              {{ getMessageByCode('export') ??'Export' }}<el-icon class="el-icon--right"><i class="bi bi-chevron-down"></i></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu class="w-100">
                <el-dropdown-item @click="ExportToExcel()">
                  <i class="bi bi-file-excel me-1"></i>
                  <span>Excel</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <el-table  :data="Rooms"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column :label="getMessageByCode('property')??' Property'" prop="property" min-width="200"></el-table-column>
        <el-table-column :label="getMessageByCode('provider')??' Provider'" prop="provider" min-width="180"></el-table-column>
        <el-table-column :label="getMessageByCode('room')??' Room'" prop="room" min-width="120"></el-table-column>
        <el-table-column :label="getMessageByCode('action')??' Action'" align="center" fixed="right" width="80">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i
                  class="bi bi-info-circle-fill action-btn"
                  @click="viewRoom(scope.row.propertyId)"
              ></i>

            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import reportServices from "@/Services/ComponentsServices/reportServices";
import clientService from "@/Services/ComponentsServices/clientService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "EmptyRooms",
  components: {ToolBar},
  data() {
    return {
      loading: false,
      Rooms: [],
      translations: [],
      clientId: sessionStorage.getItem("clientId"),
      Clients: [
        {name: "All", id: -1}
      ],
      Scopes: [
        {optTitle: "All Active Tenants", optId: 1},
        {optTitle: "Allocated Tenants Only", optId: 2},
        {optTitle: "Unallocated Tenants Only", optId: 3},
      ],
      ExpiryOptions: [
        {optTitle: "All", optId: 0},
        {optTitle: "90 Days", optId: 90},
        {optTitle: "28 Days", optId: 28},
        {optTitle: "14 Days", optId: 14},
        {optTitle: "07 Days", optId: 7},
        {optTitle: "Expired", optId: -1},
      ],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        days: 0,
        typeId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    viewRoom(id) {
      this.$store.state.pageId = id;
      this.$router.push('/property');
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getRooms();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getRooms();
    },
    async getRooms() {
      try {
        this.loading = true;
        let response = await reportServices.getRooms(this.filter);
        this.Rooms = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.loading = true;
          let response = await clientService.getAll();
          this.Clients = this.Clients.concat(response.data.items ?? []);
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
        this.getRooms();
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async ExportToExcel(){
      try {
        this.loading  = true;
        // eslint-disable-next-line no-unused-vars
        let response = await reportServices.downloadRooms(this.filter);
        if (response.data == null){
          ShowMessage("error", "Something went wrong!");
          return;
        }
        let sampleArr = this.base64ToArrayBuffer(response.data);
        this.saveByteArray("Empty Rooms Report.xlsx", sampleArr);
        this.loading  = false;
        ShowMessage("success", "Report Downloaded");
      }
      catch {
        this.loading  = false;
        ShowMessage("error","Something went wrong!");
      }
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
  getMessageByCode(code) {
    // Find the object with the matching code
    if (this.translations == null) {
      this.translations = getLanguage();
      return;
    }
    const matchingObject = this.translations.find(obj => obj.code === code);
    return matchingObject ? matchingObject.message : null;
  }
},
created() {
  this.translations = getLanguage();
    this.getClients();

  }
}
</script>

<style scoped>

</style>