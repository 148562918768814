
let language = JSON.parse(sessionStorage.getItem("language"));
export function getLanguage(){
    if(language == null){
        getLanguageFromStorage();
    }
   return language;
}
function getLanguageFromStorage(){
    language = JSON.parse(sessionStorage.getItem("language"));
}