import Config from "@/Configuration/Config";
import axios from "axios"


export default {
    allurl: Config.apiBaseUrl + "compliance/getcompliances?clientId=",
    getallurl: Config.apiBaseUrl + "compliance/getcompliances?limit=1500",
    geturl: Config.apiBaseUrl + "compliance/getcompliancebyid?complianceId=",
    getbyclienturl: Config.apiBaseUrl + "compliance/getbyclient?clientId=",
    addurl: Config.apiBaseUrl + "compliance/add",
    updateurl: Config.apiBaseUrl + "compliance/update",
    deleteurl: Config.apiBaseUrl + "compliance/delete?complianceId=",


    all(filter) {
        return axios.get(this.allurl + filter.clientId + "&search=" + filter.search + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getallurl);
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    add(compliance) {
        return axios.post(this.addurl, compliance);
    },
    update(compliance) {
        return axios.put(this.updateurl, compliance);
    },
    deleteCompliance(id) {
        return axios.delete(this.deleteurl + id);
    }


}