import axios from "axios"
import Config from "@/Configuration/Config";


export default {

    //Compliance Docs
    getbyPropertyUrl: Config.apiBaseUrl + "compliancepropertydocs/getdocs?proId=",
    downloadurl: Config.apiBaseUrl + "compliancepropertydocs/download?docId=",
    deleteurl: Config.apiBaseUrl + "compliancePropertyDoc/delete?compliancePropertyDocId=",
    addComplianceDocUrl: Config.apiBaseUrl + "compliancePropertyDoc/add",


    //Compliance Docs
    getbyProperty(propertyId) {
        return axios.get(this.getbyPropertyUrl + propertyId + "&limit=100");
    },
    addComplianceDoc(document) {
        return axios.post(this.addComplianceDocUrl, document);
    },
    download(id){
        return axios.get(this.downloadurl+id);
    },
    delete(id) {
        return axios.delete(this.deleteurl + id);
    }

}