<template>
  <div class="common-layout m-0 p-0">
    <el-container  style="height: 100vh;">
      <el-aside v-if="$store.state.loggedIn" class="d-none d-md-block" width="200" >
        <Sidebar />
      </el-aside>
      <el-container >
        <el-header v-if="$store.state.loggedIn" class="m-0 p-0">
          <HeaderComp/>
        </el-header>
        <el-main class="m-0 p-0">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>


</template>
<script>

import HeaderComp from "@/components/Navigation/Header";
import Sidebar from "@/components/Navigation/Sidebar";
import {mapState} from "vuex";

export default {
  components: {
    HeaderComp,
    Sidebar
  },
  data(){
    return{
      collapsed: false,
    }
  },
  computed: {
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  watch: {
    sidebarCollapsed(newVal) {
      if (newVal) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    },
  },
}
</script>
<style>
@import "./assets/bootstrap/css/bootstrap.css";
@import "./assets/MingCute/fonts/MingCute.css";
@import "./assets/Style/Style.css";
</style>
