<template>
  <el-dialog
      v-model="$store.state.addCompDlg"
      :title="btnTitle +' '+ getMessageByCode('compl') ?? ' Compliance'"
      width="40%"
  >
    <div class="container-fluid p-0">
      <el-form ref="complianceForm" :model="Compliance">
        <div class="row">
          <div class="col-12 col-md-6">
            <label class="lblText required">{{getMessageByCode('title')??'Title'}}</label>
            <el-form-item prop="title" :rules="formRules.title">
              <el-input
                  v-model="Compliance.title"
                  type="text"
              />
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <label class="lblText required">{{getMessageByCode('client')??'Client'}}</label>
            <el-form-item>
              <el-select v-if="clientId == null || clientId == 0" filterable v-model="Compliance.clientId" class="w-100">
                <el-option
                    v-for="item in Clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <label class="lblText text-capitalize">{{getMessageByCode('ren_typ')??'Renew Type'}}</label>
            <el-form-item>
              <el-select v-model="Compliance.defaulRenewType" class="w-100">
                <el-option
                    v-for="item in RenewTypes"
                    :key="item.optId"
                    :label="item.optTitle"
                    :value="item.optId"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <label class="lblText text-capitalize">{{getMessageByCode('ren_val')??'Renew Value'}}</label>
            <el-form-item>
              <el-select v-model="Compliance.defaultRenewValue" class="w-100">
                <el-option
                    v-for="item in RenewValues"
                    :key="item.optId"
                    :label="item.optTitle"
                    :value="item.optId"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col"> <label class="lblText text-capitalize">{{getMessageByCode('default')??'Default'}}</label>
                <el-form-item>
                  <el-switch v-model="Compliance.isDefault"></el-switch>
                </el-form-item>
              </div>
              <div class="col"> <label class="lblText text-capitalize">{{getMessageByCode('required')??'Required'}}</label>
                <el-form-item>
                  <el-switch v-model="Compliance.isRequired"></el-switch>
                </el-form-item>
              </div>
              <div class="col"><label class="lblText text-capitalize">{{getMessageByCode('visible')??'Visible'}}</label>
                <el-form-item>
                  <el-switch v-model="Compliance.isVisibleToProvider"></el-switch>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.$store.state.addCompDlg = false" text bg>Cancel</el-button>
        <el-button  type="primary" :disabled="Compliance.clientId < 1"
                   @click="AddOrUpdate()">
          {{ btnTitle }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ShowMessage} from "@/Utility/Utility";
import complianceService from "@/Services/ComponentsServices/complianceService";
import ClientService from "@/Services/ComponentsServices/clientService";
import optionService from "@/Services/ComponentsServices/optionService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "AddCompliance",
  props: {
    complianceId: {
      type: Number,
      default: 0,
      required: true,
    }
  },
  data() {
    return {
      visibleDialog: this.showDialog,
      translations:[],
      id: 0,
      RenewValues: [],
      RenewTypes: [],
      RenewValuesType: [],
      formRules: {
        title: [
          {required: true, message: 'Please enter the title', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
      },
      Compliance: {
        id: 0,
        title: "",
        isDefault: true,
        sortOrder: 0,
        isRequired: false,
        defaultRenewValue: 1,
        defaulRenewType: 1,
        isVisibleToProvider: false,
        clientId: sessionStorage.getItem('clientId'),
      },
      btnTitle: this.getMessageByCode('add')??'Add',
      Clients: [],
      clientId: sessionStorage.getItem('clientId'),
    }
  },
  watch: {
    complianceId: {
      handler(newVal) {
        if (newVal > 0) {
          this.btnTitle = this.getMessageByCode('update')??'Update';
          this.getCompliance(newVal);
        } else {
          this.resetCompliance();
        }
      }
    },
  },
  methods: {
    async getCompliance(id) {
      try {
        let response = await complianceService.getbyId(id);
        this.Compliance = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    AddOrUpdate() {
      switch (this.Compliance.id) {
        case 0:
          this.AddCompliance();
          break;
        default:
          this.UpdateCompliance();
          break;
      }
    },
    async AddCompliance() {
      this.$refs.complianceForm.validate(async (valid) => {
            if (valid) {
              try {
                // eslint-disable-next-line no-unused-vars
                let response = await complianceService.add(this.Compliance)
                ShowMessage("success", "Compliance added successfully");
                this.$emit("addSuccess");
                this.$store.state.addCompDlg = false;
              } catch (e) {
                console.log(e)
                ShowMessage("error", "Something went wrong!.")
              }
            }
          }
      );
    },
    async UpdateCompliance() {

      this.$refs.complianceForm.validate(async (valid) => {
            if (valid) {
              try {
                // eslint-disable-next-line no-unused-vars
                let response = await complianceService.update(this.Compliance)

                ShowMessage("success", "Compliance updated successfully");
                this.$emit("addSuccess");
                this.$store.state.addCompDlg = false;
              } catch (e) {
                console.log(e)
                ShowMessage("error", "Something went wrong!.")
              }
            }
          }
      );
    }
    ,
    resetCompliance() {
      this.Compliance = {
        id: 0,
        title: "",
        isDefault: true,
        sortOrder: 0,
        isRequired: false,
        defaultRenewValue: 0,
        defaulRenewType: 0,
        clientId: sessionStorage.getItem('clientId'),
        isVisibleToProvider: false
      }
      this.getRenewTypes();
      this.getRenewValues();
    }
    ,
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.$store.state.loading = true;
          let response = await ClientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.Compliance.clientId = this.Clients[0].id;
          }
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    }
    ,
    async getRenewValues() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.clientId,24)
        this.RenewValues = response.data ?? [];
        if (this.RenewValues.length > 0) {
          this.Compliance.defaultRenewValue = this.RenewValues[0].optId;
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    }
    ,
    async getRenewTypes() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.clientId,25)
        this.RenewTypes = response.data ?? [];
        if (this.RenewTypes.length > 0) {
          this.Compliance.defaulRenewType = this.RenewTypes[0].optId;
        }
      } catch (e) {
        console.log(e);
        this.$store.state.loading = false;
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getClients();
    this.getRenewValues();
    this.getRenewTypes();
    this.btnTitle = this.getMessageByCode('add')??'Add';
  }
}
</script>

<style scoped>

</style>