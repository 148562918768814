
import Config from "@/Configuration/Config";
import axios from "axios"

export default {
   adminUrl: Config.apiBaseUrl+ "dashboard/getadmindata",
    clientUrl: Config.apiBaseUrl+"dashboard/getdata?clientId=",
    getadmindata(){
       return axios.get(this.adminUrl);
    },
    getdata(id){
       return axios.get(this.clientUrl+id)
    }


}