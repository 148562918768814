<template>
  <div>
    <el-dialog
        v-model="$store.state.addComplianceDocDialog"
        :title="cardTitle + ' Compliance Document'"
        align-center
        draggable
        width="30%"
    >
      <el-form :model="Doc" label-position="top">
        <el-form-item prop="noteCategoryId" label="Category">
          <template #label>
            <span class="required text-capitalize">{{getMessageByCode('doc_type')}}</span>
          </template>
          <el-select v-model="Doc.docType" class="w-100" filterable>
            <el-option v-for="opt in DefaultDocTypes" :key="opt.optId" :label="opt.optTitle"
                       :value="opt.optTitle"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="text-capitalize" :label="getMessageByCode('title') ?? 'Title'">
          <el-input v-model="Doc.title"></el-input>
        </el-form-item>
        <el-form-item class="text-capitalize" :label="getMessageByCode('doc')??'Document'">
          <el-input v-model="Doc.name" disabled>
            <template #prepend>
              <el-upload :auto-upload="false" :on-change="handleFileChange" :show-file-list="false"
                         class="mb-0">
                <template #trigger>
                  <el-button type="primary text-capitalize" >{{getMessageByCode('select_file')??'Select File'}}></el-button>
                </template>
              </el-upload>
            </template>
          </el-input>
          <div class="w-100 d-flex justify-content-end">
            <el-space>
              <span class="custom-caption" v-if="Doc.size > 0">{{ getFormattedSize(Doc.size) }}</span>
              <span class="custom-caption">{{ Doc.extension }}</span>
            </el-space>
          </div>
        </el-form-item>
        <el-form-item class="text-capitalize" :label="getMessageByCode('valid_from')??'Valid From'">
          <el-date-picker v-model="Doc.validFromDate" format="DD-MM-YYYY" :editable="false"
                          class="w-100"></el-date-picker>
        </el-form-item>
        <el-form-item class="text-capitalize" :label="getMessageByCode('valid_to')??'Valid To'">
          <el-date-picker v-model="Doc.validToDate" format="DD-MM-YYYY" :editable="false"
                          class="w-100"></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <el-button text bg @click="$store.state.addComplianceDocDialog = false">{{ getMessageByCode('cancel')??'Cancel' }}</el-button>
            <el-button :disabled="!Doc.name" type="primary" class="ms-2" @click="AddOrUpdate(); $store.state.addComplianceDocDialog = false">
              {{ cardTitle }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import store from "@/store";
import optionService from "@/Services/ComponentsServices/optionService";
import {ShowMessage} from "@/Utility/Utility";
import SizeCalc from "@/components/Services/SizeCalc";
import documentService from "@/Services/ComponentsServices/documentService";
import complianceDocService from "@/Services/ComponentsServices/complianceDocService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  emits: ['docadded', 'update:loading'],
  props: {
    docId: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      cardTitle: "Add",
      DefaultDocTypes: [],
      translations: [],
      Doc: {
        "id": 0,
        "complianceId": 0,
        "compliancePropertyId":store.state.pageId,
        "propertyId":store.state.pageId,
        "title": "",
        "fileName": "",
        "validFromDate": new Date(),
        "validToDate": new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        "name": "",
        "extension": "",
        "mimeType": "",
        "docType": "",
        "docObject": "",
        "size": 0,

      },
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        propertyId: store.state.pageId,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  watch: {
    docId: {
      handler(newVal) {
        if (newVal > 0) {
          this.cardTitle = "Update"
          this.Doc.id = newVal;
          this.getDoc(this.Doc.id);
        } else {
          this.resetDoc();
        }
      }
    },
  },
  methods: {
    getFormattedSize(size) {
      if (size > 0) {
        return SizeCalc.FormattedSize(size)
      } else {
        return 0;
      }
    },
    handleFileChange(file) {
      this.Doc.name = file.raw.name;
      this.Doc.size = file.raw.size;
      this.Doc.extension = file.raw.name.split('.').pop();
      this.Doc.mimeType = file.raw.type;
      try {
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            this.Doc.docObject = base64String;
          };
          reader.readAsArrayBuffer(file.raw);
        }
      } catch (error) {
        console.error(error);
      }
    },
    AddOrUpdate() {
      let id = this.Doc.id;
      if (id > 0) {
        this.UpdateDoc();
      } else if (id === 0) {
        this.AddDoc();
      } else {
        this.AddDoc();
      }
    },
    async getDoc(id) {
      try {
        let response = await documentService.getbyId(id);
        this.Note = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async getNotesCategory() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.filter.clientId ,10);
        this.Categories = response.data ?? [];
        /* if (this.Categories.length > 0) {
           this.Doc.noteCategoryId = this.Categories[0].optId;
         }*/
      } catch (e) {
        console.log()
      }
    },
    async AddDoc() {
      try {
        this.$emit("update:loading", true)
        let response = await complianceDocService.addComplianceDoc(this.Doc);
        if (response.status === 200) {
          ShowMessage("success", "Document added successfully");
          this.$emit("docadded")
        }
      } catch (e) {
        this.$emit("update:loading", false)
        console.log(e)
      }
    },
    async UpdateDoc() {
      try {
        this.$emit("update:loading", true)
        let response = await documentService.addComplianceDoc(this.Doc);
        if (response.status === 200) {
          ShowMessage("success", "Document updated successfully");
          this.$emit("docadded")
        }
      } catch (e) {
        this.$emit("update:loading", false)
        console.log(e)
      }
    },
    resetDoc() {
      this.cardTitle = "Add";
      this.Doc = {
        "id": 0,
        "complianceId": 0,
        "compliancePropertyId": 0,
        "propertyId": store.state.pageId,
        "complianceName": "",
        "title": "",
        "fileName": "",
        "validFromDate": null,
        "validToDate": null,
        "updatedBy": "",
        "uploadedDate": null,
        "name": "",
        "extension": "",
        "mimeType": "",
        "docObject": "",
        "size": 0
      };
      this.getNotesCategory();
    },
    async getDefaultDocTypes() {
      try {
        let response = await optionService.getOptionsByHeaderId(this.filter.clientId,12);
        this.DefaultDocTypes = response.data ?? [];
        if (this.DefaultDocTypes.length > 0) {
          this.Doc.docType = this.DefaultDocTypes[0].optTitle
        }
      } catch (e) {
        console.log(e)
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getDefaultDocTypes();
  }
}
</script>

<style scoped>

</style>