import { createStore } from 'vuex'
import {getLanguage} from "@/Utility/getLanguage";

export default createStore({
  state: {
    darkTheme: false,
    pageId:0,
    sidebarCollapsed: false,
    addUserDialog: false,
    addNoteDialog: false,
    addComplianceDocDialog: false,
    loggedIn: false,
    percentage: 0,
    loading: false,
    deleteDialog: false,
    allDeleteDialog: false,
    roleId: 0,
    refreshProfile: false,
    securityRoleId: 0,
    selectedIds:[],
    entityName:"",
    addCompDlg: false,
    addDocumentD: false,
    language: getLanguage(),
    clientId: 0,
    roleName: sessionStorage.getItem("roleName"),
    entityId: 0,
    Clients:[],
    filter: {
      clientId: 1,
      providerId: 1,
      search: "",
      sort: "",
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
    },
  },
  getters: {
  },
  mutations: {
    setDialog(state, value) {
      state.deleteDialog = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
