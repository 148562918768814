import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allurl: Config.apiBaseUrl + "template/getTemplates?clientId=",
    getallurl: Config.apiBaseUrl + "template/getTemplates&limit=1500",
    geturl: Config.apiBaseUrl + "template/gettemplatebyid?etId=",
    addurl: Config.apiBaseUrl + "template/add",
    updateurl: Config.apiBaseUrl + "template/update",
    deleteurl: Config.apiBaseUrl + "template/delete?etId=",
    forcedeleteurl: Config.apiBaseUrl + "template/forcedelete?etId=",


    all(filter) {
        return axios.get(this.allurl+filter.clientId+"&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getallurl);
    },
    getbyId(etId) {
        return axios.get(this.geturl + etId);
    },
    addTemplate(template) {
        return axios.post(this.addurl, template);
    },
    updateTemplate(template) {
        return axios.put(this.updateurl, template);
    },
    deleteTemplate(etId) {
        return axios.delete(this.deleteurl + etId);
    },
    forceDelete(etId) {
        return axios.delete(this.forcedeleteurl + etId);
    },
}