import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allUrl: Config.apiBaseUrl + "config/getconfigs?clientId=",
    getbyKey: Config.apiBaseUrl + "config/getconfigbykey?clientId=",
    addUrl: Config.apiBaseUrl + "config/add",
    all(filter) {
        return axios.get(this.allUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getByKey(clientId, key) {
        return axios.get(this.getbyKey + clientId + "&key=" + key);
    },
    add(config) {
        return axios.post(this.addUrl, config)
    }

}