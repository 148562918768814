import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    expirydocurl: Config.apiBaseUrl+"report/getexpirydocreport?clientId=",
    expirydocurlExport: Config.apiBaseUrl+"report/expirydocs/exporttoexcel?clientId=",
    missingdocurl: Config.apiBaseUrl+"report/getmissingdocreport?clientId=",
    missingdocurlExport: Config.apiBaseUrl+"report/missingdocs/exporttoexcel?clientId=",
    tenanturl: Config.apiBaseUrl+"report/gettenantreport?clientId=",
    tenanturlExport: Config.apiBaseUrl+"report/tenant/exporttoexcel?type=",
    roomurl: Config.apiBaseUrl+"report/emptyroomsreport?clientId=",
    roomExporturl: Config.apiBaseUrl+"report/emptyrooms/exporttoexcel?clientId=",




    getExpiredDocs(filter){
        return axios.get(this.expirydocurl+filter.clientId+"&days="+filter.days+"&typeId="+filter.typeId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },
    getMissingDocs(filter){
        return axios.get(this.missingdocurl+filter.clientId+"&id="+filter.typeId);
    },
    getTenants(filter){
        return axios.get(this.tenanturl+filter.clientId +"&type="+filter.typeId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },
    getRooms(filter){
        return axios.get(this.roomurl+filter.clientId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },
    downloadExpiryDocs(filter){
        return axios.get(this.expirydocurlExport+filter.clientId+"&days="+filter.days+"&type="+filter.typeId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },
    downloadMissingDocs(filter){
        return axios.get(this.missingdocurlExport+filter.clientId+"&id="+filter.typeId);
    },
    downloadTenants(filter){
        return axios.get(this.tenanturlExport+filter.typeId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },
    downloadRooms(filter){
        return axios.get(this.roomExporturl+filter.clientId+"&limit="+filter.pageSize+"&page="+filter.currentPage);
    },

}