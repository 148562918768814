<template>
  <el-scrollbar class="custom-scrollable">
    <el-menu
        :collapse="collapsed"
        :router="true"
        background-color="#131313"
        class="el-menu-vertical-demo d-flex flex-column justify-content-between h-100"
        text-color="#fff"

    >
      <div >
        <el-menu-item
            class="d-flex justify-content-center align-items-center"
            style="height: 60px"
        >
          <!--          <span class="mt-2" v-if="!$store.state.collapsed">LIGHT CRM</span>
                    <span class="mt-2" v-else>CRM</span>-->
          <img v-if="!collapsed" :src="logo" width="100"/>
          <img v-else :src="icon" width="25"/>
        </el-menu-item>
        <el-divider border-style="dashed" class="mt-0"></el-divider>
        <el-menu-item
            v-if="clientId <= 1"
            :class="{ 'active-menu-item': $route.path === '/dashboard' }"
            index="1"
            route="dashboard"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{ getMessageByCode('dashboard') ?? 'Dashboard' }}</template>
        </el-menu-item>
        <el-menu-item
            v-else
            :class="{ 'active-menu-item': $route.path === '/home' }"
            index="1"
            route="home"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{getMessageByCode('dashboard') ?? 'Dashboard'}}</template>
        </el-menu-item>
        <el-menu-item

            v-if="clientId <= 1 && clientView"
            :class="{ 'active-menu-item': $route.path === '/clients' }"
            index="2"
            route="clients"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-check"></i>
          </el-icon>
          <template #title>{{getMessageByCode('clients') ?? 'Clients'}}</template>
        </el-menu-item>
        <el-menu-item v-if="providerView"
            :class="{ 'active-menu-item': $route.path === '/providers' }"
            index="3"
            route="providers"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-check"></i>
          </el-icon>
          <template #title>{{getMessageByCode('providers') ?? 'Providers'}}</template>
        </el-menu-item>
        <el-menu-item
            v-if="propertyView"
            :class="{ 'active-menu-item': $route.path === '/properties' }"
            index="properties"
            route=""
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-building-check"></i>
          </el-icon>
          <template #title>{{getMessageByCode('properties') ?? 'Properties'}}</template>
        </el-menu-item>
        <el-menu-item
            v-if="roomView"
            :class="{ 'active-menu-item': $route.path === '/empty_rooms' }"
            class="mt-auto"
            index="10"
            route="empty_rooms"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-gear"></i>
          </el-icon>
          <template #title>{{getMessageByCode('rooms') ?? 'Rooms'}}</template>
        </el-menu-item>
        <el-menu-item
            v-if="tenantView"
            :class="{ 'active-menu-item': $route.path === '/tenants' }"
            index="7"
            route="tenants"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-add"></i>
          </el-icon>
          <template #title>{{getMessageByCode('tenants') ?? 'Tenants'}}</template>
        </el-menu-item>
        <el-sub-menu index="19"  v-if="reportView">

          <template #title>
            <el-icon>
              <i class="bi bi-printer"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed">{{getMessageByCode('reports') ?? 'Reports'}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="docExpView"
                :class="{ 'active-menu-item': $route.path === '/document_expiry' }"
                class="mt-auto"
                index="21"
                route="document_expiry"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-file-earmark-x"></i>
              </el-icon>
              <template #title>{{getMessageByCode('doc_exp') ?? 'Documents Expiry'}}</template>
            </el-menu-item>
            <el-menu-item
                v-if="missDocView"
                :class="{ 'active-menu-item': $route.path === '/missing_documents' }"
                class="mt-auto"
                index="22"
                route="missing_documents"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-file-earmark"></i>
              </el-icon>
              <template #title>{{getMessageByCode('miss_documents') ?? 'Missing Documents'}}</template>
            </el-menu-item>
            <el-menu-item
                v-if="tenantReportView"
                :class="{ 'active-menu-item': $route.path === '/tenants_report' }"
                class="mt-auto"
                index="23"
                route="tenants_report"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title>{{getMessageByCode('tenants') ?? 'Tenants'}}</template>
            </el-menu-item>
            <el-menu-item
                v-if="roomReportView"
                :class="{ 'active-menu-item': $route.path === '/empty-rooms' }"
                class="mt-auto"
                index="24"
                route="empty-rooms"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-house-lock"></i>
              </el-icon>
              <template #title>{{getMessageByCode('empt_rooms') ?? 'Empty Rooms'}}</template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item
            v-if="clientId <= 1 && complianceView"
            :class="{ 'active-menu-item': $route.path === '/compliances' }"
            index="4"
            route="compliances"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-file-earmark-medical"></i>
          </el-icon>
          <template #title>{{getMessageByCode('compliances') ?? 'Compliances'}}</template>
        </el-menu-item>


        <el-menu-item
            v-if="docsView"
            :class="{ 'active-menu-item': $route.path === '/documents' }"
            index="8"
            route="documents"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-folder"></i>
          </el-icon>
          <template #title><span class="text-capitalize">{{getMessageByCode('documents') ?? 'Documents'}}</span></template>
        </el-menu-item>
        <el-menu-item
            v-if="profileEdit || profileView"
            :class="{ 'active-menu-item': $route.path === '/profile' }"
            index="9"
            route="profile"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-gear"></i>
          </el-icon>
          <template #title>{{getMessageByCode('my_prof') ?? 'My Profile'}}</template>
        </el-menu-item>

        <el-sub-menu index="30" v-if="optionView || userView || roleView || templateView">
          <template #title>
            <el-icon>
              <i class="bi bi-sliders"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed" class="text-capitalize">{{getMessageByCode('more') ?? 'More'}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="optionView"
                :class="{ 'active-menu-item': $route.path === '/options' }"
                class="mt-auto"
                index="11"
                route="options"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-sliders"></i>
              </el-icon>
              <template #title>{{getMessageByCode('oprtions') ?? 'Options'}}</template>
            </el-menu-item>
            <el-menu-item
                v-if="userView"
                :class="{ 'active-menu-item': $route.path === '/users' }"
                class="mt-auto"
                index="12"
                route="users"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title>{{getMessageByCode('users') ?? 'Users'}}</template>
            </el-menu-item>
            <el-menu-item
                v-if="roleView"
                :class="{ 'active-menu-item': $route.path === '/roles' }"
                class="mt-auto"
                index="13"
                route="roles"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{getMessageByCode('roles_list') ?? 'Roles List'}}</span></template>
            </el-menu-item>
            <el-menu-item
                v-if="templateView"
                :class="{ 'active-menu-item': $route.path === '/templates' }"
                class="mt-auto"
                index="14"
                route="templates"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-envelope"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{getMessageByCode('templates') ?? 'Templates'}}</span></template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="40"  v-if="clientId == null || clientId == 0">
          <template #title>
            <el-icon>
              <i class="bi bi-currency-exchange"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed"><span class="text-capitalize">{{getMessageByCode('mar_pack') ?? 'Markets & Packages'}}</span></span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="clientId <= 1"
                :class="{ 'active-menu-item': $route.path === '/markets' }"
                class="mt-auto"
                index="41"
                route="markets"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-shop-window"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{getMessageByCode('markets') ?? 'Markets'}}</span></template>
            </el-menu-item>
            <el-menu-item
                v-if="clientId <= 1"
                :class="{ 'active-menu-item': $route.path === '/packages' }"
                class="mt-auto"
                index="42"
                route="packages"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-boxes"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{getMessageByCode('packages') ?? 'Packages'}}</span></template>
            </el-menu-item>

          </el-menu-item-group>
        </el-sub-menu>
      </div>
      <el-menu-item
          :class="{ 'active-menu-item': $route.path === '/settings' }"
          class="mt-auto"
          index="15"
          route="settings"
          @click="$emit('closeSidebar')"
      >
        <el-icon>
          <i class="bi bi-gear"></i>
        </el-icon>
        <template #title><span class="text-capitalize">{{getMessageByCode('settings') ?? 'Settings'}}</span></template>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>

</template>

<script>
import configService from "@/Services/ComponentsServices/configService";
import {mapState} from "vuex";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "SideBar",
  data() {
    return {
      clientId: sessionStorage.getItem("clientId"),
      translations:[],
      logo: "",
      icon: "",
      collapsed: false,
      providerView:false,
      propertyView: false,
      reportView: false,
      clientView: false,
      roomView: false,
      tenantView: false,
      complianceView: false,
      userView: false,
      roleView: false,
      templateView: false,
      docExpView: false,
      missDocView: false,
      roomReportView: false,
      tenantReportView: false,
      optionView: false,
      docsView: false,
      profileView:false,
      profileEdit:false,
    }
  },
  computed: {
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  watch: {
    sidebarCollapsed(newVal) {
      if (newVal) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    },
  },
  methods: {
    async getTitle(clientId) {
      try {
        const logoNormal = await configService.getByKey(clientId, 'BrandLogoNormal');
        const icon = await configService.getByKey(clientId, 'BrandIcon');
        if (logoNormal.data.value != undefined) {
          // Replace with your actual API endpoint
          this.logo = logoNormal.data.value
        }
        if (icon.data.value != undefined) {
          // Replace with your actual API endpoint
          this.icon = icon.data.value
        }

      } catch (error) {
        console.error('Error fetching app data', error);
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.providerView = checkPermission("MD_PRO_VIEW");
    this.propertyView = checkPermission("MD_PROP_VIEW");
    this.reportView = checkPermission("MD_REPORT_VIEW");
    this.clientView = checkPermission("MD_CLIENT_VIEW");
    this.roomView = checkPermission("MD_ROOM_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.complianceView = checkPermission("MD_COMP_VIEW");
    this.userView = checkPermission("MD_USER_VIEW");
    this.roleView = checkPermission("MD_ROLE_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.missDocView = checkPermission("MD_DOC_MISS_VIEW");
    this.docExpView = checkPermission("MD_DOC_EXP_VIEW");
    this.tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
    this.roomReportView = checkPermission("MD_ROOM_REP_VIEW");
    this.templateView = checkPermission("MD_TEMPLATE_VIEW");
    this.optionView = checkPermission("MD_LIST_VIEW");
    this.docsView = checkPermission("MD_DOCUMENT_VIEW");
    this.profileView = checkPermission("MD_USER_PROFILE_VIEW");
    this.profileEdit = checkPermission("MD_USER_PROFILE_EDIT");
    this.getTitle(sessionStorage.getItem("clientId") ?? 0);

  }
};
</script>

<style scoped>
.custom-scrollable {
  height: 100vh !important;
}

.active-menu-item {
  background-color: #262626 !important;
  padding: 20px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 100vh !important;
}
.el-scrollbar__view {
  height: 100% !important;
}
</style>
