<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="false" :show-bread="false" :show-select="false"/>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <router-link to="properties" class="text-decoration-none">
          <div class="card p-3 py-4 shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
              <h3> {{ getMessageByCode('properties') ?? 'Properties' }}</h3>
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-building-fill"></i>
              </div>
            </div>
            <div class="mt-4">
              <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('properties') ?? 'Properties' }}</span>
                  <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                      Dashboard.totalProperty !== null && Dashboard.totalProperty !== '' ? Dashboard.totalProperty : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      Dashboard.assignedProperty !== null && Dashboard.assignedProperty !== '' ? Dashboard.assignedProperty : "0"
                    }}</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12 d-flex justify-content-between align-items-center w-100">
                  <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                  <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                      Dashboard.occupancyProperty !== null && Dashboard.occupancyProperty !== '' ? Dashboard.occupancyProperty : "0"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-12 mt-3 mt-md-0 mt-lg-0 col-md-6 col-lg-4">
        <div class="card p-3 py-4 shadow-sm">
          <div class="d-flex justify-content-between align-items-center">
            <h3>{{getMessageByCode('rooms')?? 'Rooms' }}</h3>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-bank2"></i>
            </div>
          </div>
          <div class="mt-4">
            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{getMessageByCode('total_rooms') ?? 'Total Rooms' }}</span>
                <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                    Dashboard.totalRoom !== null && Dashboard.totalRoom !== '' ? Dashboard.totalRoom : "0"
                  }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{ getMessageByCode('assigned') ?? 'Assigned' }}</span>
                <span
                    class="text-caption btn-bl-secondary  px-2 rounded-5">{{ Dashboard.assignedRoom !== null && Dashboard.assignedRoom !== '' ? Dashboard.assignedRoom : "0" }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{ getMessageByCode('percentage')?? 'Percentage' }}</span>
                <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                    Dashboard.occupancyRoom !== null && Dashboard.occupancyRoom !== '' ? Dashboard.occupancyRoom : "0"
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 mt-3 mt-lg-0 col-lg-4">
        <div class="card p-3 py-4 shadow-sm">
          <div class="d-flex justify-content-between align-items-center">
            <h3>{{ getMessageByCode('tenants') ?? 'Tenants' }}</h3>
            <div class="d-icon-div btn-bl-secondary">
              <i class="bi bi-person-fill"></i>
            </div>
          </div>
          <div class="mt-4">
            <div class="row">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{ getMessageByCode('total_tenants') ?? 'Total Tenants' }}</span>
                <span class="text-caption btn-bl-secondary px-2 rounded-5">{{
                    Dashboard.totalTenant !== null && Dashboard.totalTenant !== '' ? Dashboard.totalTenant : "0"
                  }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{getMessageByCode('assigned') ?? 'Assigned' }}</span>
                <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                    Dashboard.assignedTenant !== null && Dashboard.assignedTenant !== '' ? Dashboard.assignedTenant : "0"
                  }}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 d-flex justify-content-between align-items-center w-100">
                <span class="text-caption mfw-bold">{{ getMessageByCode('percentage') ?? 'Percentage' }}</span>
                <span class="text-caption btn-bl-secondary  px-2 rounded-5">{{
                    Dashboard.occupancyTenant !== null && Dashboard.occupancyTenant !== '' ? Dashboard.occupancyTenant : "0"
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div>
        <h3>{{ getMessageByCode('documents') ?? 'Documents' }}</h3>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-lg-0 col-lg-3">
        <div class="card p-3 py-5 shadow-sm">
          <div class="row align-items-center">
            <div class="col-12 col-md-3">
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-folder-fill"></i>
              </div>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
              <span class="fs-1">{{
                  Dashboard.missingDoc !== null && Dashboard.missingDoc !== '' ? Dashboard.missingDoc : "0"
                }}</span>
              <span class="text-caption">{{ getMessageByCode('miss_documents') ?? 'Missing Documents' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-lg-0 col-lg-3">
        <div class="card p-3 py-5 shadow-sm">
          <div class="row align-items-center">
            <div class="col-12 col-md-3">
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-file-earmark-excel-fill"></i>
              </div>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
              <span class="fs-1">{{
                  Dashboard.expiredDoc !== null && Dashboard.expiredDoc !== '' ? Dashboard.expiredDoc : "0"
                }}</span>
              <span class="text-caption">{{ getMessageByCode('exp_documents') ?? 'Expired Documents' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-lg-0 col-lg-3">
        <div class="card p-3 py-5 shadow-sm">
          <div class="row align-items-center">
            <div class="col-12 col-md-3">
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-7-circle-fill"></i>
              </div>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
              <span class="fs-1">{{
                  Dashboard.expiredDoc7 !== null && Dashboard.expiredDoc7 !== '' ? Dashboard.expiredDoc7 : "0"
                }}</span>
              <span class="text-caption">{{ getMessageByCode('expiring') ?? 'Expiring '}}{{getMessageByCode('in') ?? 'in ' }} 7 {{getMessageByCode('days') ?? 'days' }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-lg-0 col-lg-3">
        <div class="card p-3 py-5 shadow-sm">
          <div class="row align-items-center">
            <div class="col-12 col-md-3">
              <div class="d-icon-div btn-bl-secondary">
                <i class="bi bi-exclamation-circle-fill"></i>
              </div>
            </div>
            <div class="col-12 col-md-9 d-flex flex-column justify-content-center align-items-center">
              <span class="fs-1">{{
                  Dashboard.expiredDoc28 !== null && Dashboard.expiredDoc28 !== '' ? Dashboard.expiredDoc28 : "0"
                }}</span>
              <span class="text-caption">{{ getMessageByCode('expiring') ?? 'Expiring '}}{{getMessageByCode('in') ?? 'in ' }} 28 {{getMessageByCode('days') ?? 'days' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import dashboardService from "@/Services/ComponentsServices/dashboardService";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "SLDashboard",
  components: {ToolBar},
  computed: {
    BreedCrumb() {
      return this.$route.meta.breadcrumbs
    }
  },
  data() {
    return {
      loading: false,
      clientId: sessionStorage.getItem('clientId'),
      translations:[],
      Dashboard: {
        "totalProperty": "",
        "assignedProperty": "",
        "occupancyProperty": "",
        "totalTenant": "",
        "assignedTenant": "",
        "occupancyTenant": "",
        "totalRoom": "",
        "assignedRoom": "",
        "occupancyRoom": "",
        "missingDoc": "",
        "expiredDoc": "",
        "expiredDoc7": "",
        "expiredDoc28": ""
      }
    }
  },
  methods: {
    async getAdminData() {
      try {
        this.loading = true;
        let response = await dashboardService.getdata(this.clientId);
        this.Dashboard = response.data;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      const matchingObject = this.translations.find(obj => obj.code === code);

      // If a matching object is found, return its message; otherwise, return a default message or null
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.getAdminData();
    this.translations = getLanguage();
  }
};
</script>

<style scoped>
.d-loader {
  margin-top: -10px;
}
</style>
