<template>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="$store.state.filter.totalCount"
      :page="$store.state.filter.currentPage"
      :page-size="$store.state.filter.pageSize"
      :page-sizes="[10, 20, 30, 40]"
      layout=" prev, pager, next,sizes"
      class="bg-gray p-2"
      background
      small
  >
  </el-pagination>
</template>

<script>
export default {
  name: "PaginationComp",
  emits:['sizeChanged','pageChanged','page-changed','current-page-changed','page-size-changed','size-changed'],
  props: {
    pagination:{
      type: Object,
      required: false,
      default: function() {
        return {
          id: 0,
          title: "",
          isDefault: true,
          sortOrder: 0,
          isRequired: false,
          defaultRenewValue: 0,
          defaulRenewType: 0,
          isVisibleToProvider: false
        };
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    pageSize: {
      type: Number
    }
  },
  methods: {
    handleSizeChange(newSize) {
      this.$emit('page-size-changed', newSize);
    }, handleCurrentChange(newPage) {
      this.$emit('current-page-changed', newPage);
    },
  },
}
</script>

<style scoped>

</style>