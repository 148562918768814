<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="true" :btn-text="getMessageByCode('save')??'Save'" :show-cancel="true" back-route="roles"
             @addbtn-clicked="UpdatePermissions" :showSelect="false"/>
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="col-12 col-md-6">
          <span style="font-size: 14px">{{getMessageByCode('role_perm_desc')??'Currently you are viewing permissions for'}}
            <el-tag class="btn-p-secondary mfw-bold">{{roleName}}</el-tag>

            {{getMessageByCode('role')??'role'}}.</span>
        </div>
        <div class="col-12 col-md-6 col-lg-4 py-2">
          <el-input placeholder="Search for permission here..." v-model="filterText">
            <template #append>
              <el-button @click="getRolePermissions">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>

          </el-input>
        </div>
      </div>
      <div class="card rounded-0">
        <div class="card-header"><h5>Permissions</h5></div>
        <div class="card-body">
          <el-tree ref="myTree" :data="filteredData" :props="treeProps" node-key="checkId"
                   @check="getCheckedNodes" :default-expanded-keys="[1]"
                   :default-checked-keys="checked" show-checkbox>
          </el-tree>
        </div>
      </div>


      <!--      <el-collapse v-model="active">
              <el-collapse-item v-for="(permission, i) in Permissions" :key="i" :name="i">
                <template #title>
                  <div class="d-flex align-items-center mfw-bold">
                    <el-checkbox v-model="permission.is_checked"></el-checkbox>
                    <span class="ms-2">{{ permission.feature }}</span>
                  </div>
                </template>
                <el-space size="large">
                  <div v-for="(feature, i) in permission.permissions" :key="i" class="w-25">
                    <div class="d-flex align-items-center">
                      <el-checkbox v-model="feature.is_checked"></el-checkbox>
                      <span class="ms-2">{{ feature.permissionDesc }}</span>
                    </div>
                  </div>
                </el-space>
              </el-collapse-item>
            </el-collapse>-->
      <div v-if="loading" class="spinner-border"></div>
    </div>
  </div>
</template>
<script>
import roleService from "@/Services/ComponentsServices/roleService";
import ToolBar from "@/components/Layout/ToolBar.vue";
import {ShowMessage} from "@/Utility/Utility";
import loginServices from "@/Services/loginServices";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "RolesPermission",
  components: {ToolBar},
  data() {
    return {
      translations: [],
      loading: false,
      active: 0,
      roleName: this.$store.state.roleName,
      filterText: '',
      Permissions: [],
      checked: [],
      updatedPermissions: [],
    }
  },
  computed: {
    filteredData: function () {
      const filterText = this.filterText.toLowerCase();
      return this.Permissions.filter(node => {
        const labelMatchesFilter = node.label.toLowerCase().includes(filterText);
        const hasMatchingChildren = node.children && this.filteredData(node.children).length;
        return labelMatchesFilter || hasMatchingChildren;
      });
    },
    treeProps() {
      return {
        label: 'label',
        children: 'permissions',
      };
    },
  },
  methods: {
    async getRolePermissions() {
      try {
        this.checked = [];
        this.loading = true;
        let response = await roleService.getRolePermissions();
        this.Permissions = response.data ?? [];
        this.Permissions.forEach(p => {
          p.label = p.feature,
              p.permissions.forEach(e => {
                e.label = e.permissionDesc
                if (e.is_checked) {
                  this.checked.push(e.checkId)
                }
              })
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e)
      }
    },
    async UpdatePermissions() {
      try {
        this.loading = true;
        let updatePermissions = [];
        this.updatedPermissions.forEach(e => {
          var rolePermissions = this.$store.state.securityRoleId + "," + e.feature_Id;
          updatePermissions.push(rolePermissions)
        });
        let response = await roleService.updatePermissions(updatePermissions);
        if (response.data === 'success') {
          ShowMessage("success", "Permissions updated successfully");
        }
        this.loading = false;
        this.getRolePermissions();
      } catch (e) {
        this.loading = false;
        console.log(e.message)
      }
    },
    getChecked(permissions) {
      this.updatedPermissions = [];
      permissions.forEach(e => {
        if (typeof e.feature_Id !== 'number') {
          this.updatedPermissions.push(e);
        }
      });
    },
    getCheckedNodes() {
      const tree = this.$refs.myTree;
      let permissions = tree.getCheckedNodes();
      this.getChecked(permissions);
    },
    async getPermissions() {
      try {
        let response = await loginServices.getPermissions();
        sessionStorage.setItem("permissions", JSON.stringify(response.data));
      } catch (e) {
        console.log(e)
      }
    },
  getMessageByCode(code) {
    // Find the object with the matching code
    if(this.translations == null){
      this.translations = getLanguage();
      return ;
    }
    const matchingObject = this.translations.find(obj => obj.code === code);
    return matchingObject ? matchingObject.message : null;
  }
},
created() {
  this.translations = getLanguage();
    this.getRolePermissions();


  }
}
</script>

<style scoped>

</style>