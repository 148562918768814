
let translations = JSON.parse(sessionStorage.getItem("language"));
export function getMessageByCode(code) {
    // Find the object with the matching code
    if(translations == null){
        getTranslations();
        return ;
    }
    const matchingObject = translations.find(obj => obj.code === code);
    return matchingObject ? matchingObject.message : null;
}
function getTranslations(){
    translations = JSON.parse(sessionStorage.getItem("permissions"));
}