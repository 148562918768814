import Config from "@/Configuration/Config";
import axios from 'axios'
import store from "@/store";
export default {
    allurl: Config.apiBaseUrl + "role/getroles?search=",
    getByClienturl: Config.apiBaseUrl + "role/getroles?clientId=",
    getByRolesUrl: Config.apiBaseUrl + "role/getroleslist?clientId=",
    getPermissionsUrl: Config.apiBaseUrl + "role/getpermission?roleId=",
    addPermissionsUrl: Config.apiBaseUrl + "role/addpermission",
    getroluserUrl : Config.apiBaseUrl + "role/getroleusers?clientId=",
    updateRoleUrl: Config.apiBaseUrl +"role/update",
    addRoleUrl: Config.apiBaseUrl +"role/add",
    all(filter) {
        return axios.get(this.allurl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getByClient(filter){
        return axios.get(this.getByClienturl+filter.clientId+"&roleName="+sessionStorage.getItem("roleName")??"Admin"+"&search="+filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage)
    },
    getRolePermissions() {
        return axios.get(this.getPermissionsUrl +store.state.roleId+"&clientId="+store.state.clientId);
    },
    updatePermissions(permissions){
        return axios.post(this.addPermissionsUrl, permissions)
    },
    getRoleUsers(clientId){
        return axios.get(this.getroluserUrl + clientId+"&roleName="+sessionStorage.getItem("roleName")??"Admin");
    },
    update(role){
        return axios.put(this.updateRoleUrl, role)
    },
    getRolesList(clientId){
        return axios.get(this.getByRolesUrl + clientId);
    },
    add(role){
        return axios.post(this.addRoleUrl, role)
    }
}