
let permissions = JSON.parse(sessionStorage.getItem("permissions"));
export function checkPermission(permission){
    if(permissions == null){
        getPermissions();
    }
    if(permissions !== null){
        let per = permission;
        return  permissions.some(
            (permission) => permission.permission === per
        );
    }
    else return false;
}
function getPermissions(){
    permissions = JSON.parse(sessionStorage.getItem("permissions"));
}