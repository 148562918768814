
import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allurl: Config.apiBaseUrl + "room/getrooms?proId=",
    getallurl: Config.apiBaseUrl + "room/getrooms?limit=1500",
    geturl: Config.apiBaseUrl + "room/getroombyid?id=",
    getbyclienturl: Config.apiBaseUrl + "room/getbyclient?clientId=",
    addurl: Config.apiBaseUrl + "room/add",
    updateurl: Config.apiBaseUrl + "room/update",
    deleteurl: Config.apiBaseUrl + "room/delete?roomId=",
    emptyroomsurl: Config.apiBaseUrl +"room/getemptyrooms?clientId=",

    all(filter) {
        return axios.get(this.allurl+filter.propertyId +"&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + 100 + "&page=" + 1);
    },
    getAll(proId) {
        return axios.get(this.getallurl +"&proId="+proId);
    },
    getbyId(id) {
        return axios.get(this.geturl + id);
    },
    add(room) {
        return axios.post(this.addurl, room);
    },
    update(room) {
        return axios.put(this.updateurl, room);
    },
    getemptyrooms(filter){
        return axios.get(this.emptyroomsurl + filter.clientId +"&search=" + filter.search +"&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    delete(roomId){
        return axios.delete(this.deleteurl+roomId)
    }
}