<!--suppress JSValidateTypes -->
<template>
  <div class="container-fluid">
    <ToolBar :show-bread="false" :options="SelectOptions" clear-id="tenantId" @selection-changed="getTenants"
             :showSelect="true" :ShowAddBtn="tenantCreate"
             routeName="tenant" :btnText="getMessageByCode('add_tnnt')??'Add Tenant'">
      <template v-slot:select>
        <el-select v-if="clientId == null || clientId == 0" class="w-100" filterable @change="ChangeClient"
                   v-model="filter.clientId">
          <el-option
              v-for="item in Clients"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </template>
    </ToolBar>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="deleteTenantDialog = !deleteTenantDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} {{ getMessageByCode('selected') ?? 'selected' }}
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" :placeholder="getMessageByCode('search') ?? 'Search here...'"
                    @clear="getTenants" v-model="filter.search"
                    @keyup="getTenants"
                    clearable>
            <template #append>
              <el-button @click="getTenants">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table :data="Tenants" show-overflow-tooltip :default-sort="{ prop: 'firstName', order: 'ascending' }"
                    :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                    header-cell-class-name="tbl-header"
                    stripe @selection-change="handleSelectionChange">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <!--            <el-table-column align="center" type="selection" width="55"/>-->
            <el-table-column :label="getMessageByCode('first_name') ??'First Name'" prop="firstName" sortable>
              <template #default="scope">
            <span class="router-text" @click="editTenant(scope.row, 'edit')">{{
                scope.row.firstName
              }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="getMessageByCode('last_name') ??'Last Name'" width="auto" prop="lastName"/>
            <el-table-column :label="getMessageByCode('mobile') ??'Mobile'" width="auto" prop="mobile"/>
            <el-table-column :label="getMessageByCode('email') ??'Email'" width="auto" prop="email"/>
            <el-table-column :label="getMessageByCode('address') ??'Address'" width="auto" prop="address1"/>
            <el-table-column :label="getMessageByCode('city') ??'City'" resizable width="auto" prop="city"/>
            <el-table-column align="center" fixed="right" :label="getMessageByCode('actions') ??'Actions'" width="120">
              <template #default="scope">
                <el-space alignment="center" spacer="|">
                  <i
                      class="mgc_edit_line text-primary action-btn"
                      @click="editTenant(scope.row, 'edit')"
                  ></i>
                  <i v-show="tenantDel" @click="editTenant(scope.row, 'del')"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
    <el-dialog
        v-model="deleteTenantDialog"
        width="30%"
    >
      <template #header>
        <span>{{getMessageByCode('delete') ?? 'Delete'}} {{getMessageByCode('tenant') ?? 'Tenant'}}</span>
      </template>
      <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }} {{
          tenant?.firstName
        }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteTenantDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteTenantDialog = false, deleteTenant()">
          {{ getMessageByCode('delete') ?? 'Delete' }}
        </el-button>
      </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import ToolBar from "@/components/Layout/ToolBar";
import FilterService from "@/components/Services/FilterService";
import tenantService from "@/Services/ComponentsServices/tenantService";
import store from "@/store";
import clientService from "@/Services/ComponentsServices/clientService";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "TenantList",
  components: { ToolBar,},
  data() {
    return {
      tenantId: 0,
      clientId: sessionStorage.getItem("clientId"),
      translations: [],
      deleteTenantDialog: false,
      tenantCreate: false,
      tenantDel: false,
      Tenants: [],
      Clients: [],
      selectedIds: [],
      tenant: {},
      loading: false,
      SelectOptions: [{label: "All", value: 1}, {label: "Active", value: 2}, {label: "InActive", value: 3}],
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  methods: {
    ChangeClient() {
      this.getTenants();
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    editTenant(tenant, type) {
      this.tenant = {...tenant};
      switch (type) {
        case 'edit':
          store.state.pageId = this.tenant.id;
          this.$router.push('/tenant');
          break;
        case 'del':
          this.deleteTenantDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getTenants();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getTenants();
    },

    async getTenants() {
      try {
        this.loading = true;
        let response = await tenantService.all(this.filter)
        this.Tenants = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;

      } catch (e) {
        console.log(e);

      }
      this.loading = false;
    },
    async deleteTenant() {
      this.loading = true;
      try {
        let response = await tenantService.deleteTenant(this.tenant.id)
        if (response.status === 200)
          ShowMessage("success", this.tenant.firstName + " Deleted Successfully");
        await this.getTenants();
      } catch (e) {
        console.log(e);
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong!");
      }
      this.loading = false;
    },
    async getClients() {
      try {
        if (sessionStorage.getItem('clientId') == null || sessionStorage.getItem('clientId') == 0) {
          this.loading = true;
          let response = await clientService.getAll();
          this.Clients = response.data.items ?? [];
          if (this.Clients.length > 0) {
            this.filter.clientId = this.Clients[0].id;
          }
        }
       await this.getTenants();
      } catch (e) {
        console.log(e);

      }
      this.loading = false;
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.tenantCreate = checkPermission("MD_TEN_CREATE");
    this.tenantDel = checkPermission("MD_TEN_DEL");
    FilterService.resetFilter();
    this.getClients();
  }
}
</script>

<style scoped>

</style>